import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { EmptyResponse } from '../../models/http-service-models/common.body.model';
import { OtpRequest, ValidateMobileRequest } from '../../models/http-service-models/otp.body.model';

@Injectable({
  providedIn: 'root'
})
export class OtpService {

  constructor(private http: HttpClient) { }

  sendOTP(mobileNumber: string): Observable<EmptyResponse> {
    const body: OtpRequest = {
      mobileNumber: ''+mobileNumber,
      countryCode: 'gr',
      otpProfileCode: 'MEMBER_FORM_LOGIN_APP',
      type: 'OTP_BASIC',
      languageCode: 'el'
    };

    const endpoint = `${environment.coreApiURL}/public/otp/request`;
    return this.http.post<EmptyResponse>(endpoint, body);
  }

  validate(mobileNumber:string, otp: string): Observable<HttpResponse<EmptyResponse>> {    
    const body: ValidateMobileRequest = {
     mobileNumber: mobileNumber,
     oneTimePin: otp
    };

    const endpoint = `${environment.coreApiURL}/public/otp/validate?countryCode=gr&schemaCode=0001`;
    return this.http.post<EmptyResponse>(endpoint, body, {observe: 'response'},);
  }
}
