import { Component, OnInit } from '@angular/core';
import { AbstractControl, Validators, FormGroup, FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { OtpService } from '../../../services/otp/otp.service';
import { EmptyResponse } from '../../../models/http-service-models/common.body.model';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse } from '@angular/common/http';
import { AndroidInterface } from 'src/app/models/android.model';

declare var Android: AndroidInterface;

@Component({
  selector: 'app-otp-send',
  templateUrl: './otp-send.component.html',
  styleUrls: ['./otp-send.component.css']
})
export class OtpSendComponent implements OnInit {
  submitting = false;
  mobilePhoneSendOTPForm: FormGroup = new FormGroup({});
  constructor(private router: Router, private otpService: OtpService, private snackbar:MatSnackBar) { 
    this.initForm();
  }

  ngOnInit(): void {
  }

  get mobilePhoneFormControl(): AbstractControl | null  { return this.mobilePhoneSendOTPForm.get('mobilePhone');  }

  private initForm(): void {
    this.mobilePhoneSendOTPForm = new FormGroup({
      mobilePhone: new FormControl('', [
        Validators.required,
        Validators.pattern('^69[0-9]{8}$')
      ])
    });
  }

  onMobileSubmit(): void{
    sessionStorage.setItem('mobilePhone', this.mobilePhoneFormControl.value);

    this.submitting = true;
    this.otpService.sendOTP(this.mobilePhoneFormControl.value)
      .subscribe((success: EmptyResponse) => {
        this.submitting = false;
        sessionStorage.setItem('mobilePhone', this.mobilePhoneFormControl.value);
        sessionStorage.setItem('sentOTP', 'true');
        this.router.navigate(['/mobile-validation/validate']);
      }, (error: HttpErrorResponse) =>{
        this.submitting = false;
        switch(error.status){
          case 409:
            switch(error?.error?.messages[0]?.code){
              case 1099:
                try{ Android.onError(JSON.stringify(error)); } catch (error){ }
                this.router.navigate(['/otp-limit-exceeded']);
                break;
              default: 
                this.snackbar.open('To sms δεν στάλθηκε', null, {
                  duration: 2000,
                });
            }
            break;
          default:
            this.snackbar.open('To sms δεν στάλθηκε', null, {
              duration: 2000,
            });
            break;
        }
       
        try{ Android.onError(JSON.stringify(error)); } catch (error){ }
      });
  }

}
