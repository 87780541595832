const Months: string[] = [
    'Ιανουάριος',
    'Φεβρουάριος',
    'Μάρτιος',
    'Απρίλιος',
    'Μάιος',
    'Ίούνιος',
    'Ιούλιος',
    'Άυγουστος',
    'Σεπτέμβρης',
    'Οκτώβρης',
    'Νοέμβρης',
    'Δεκέμβρης'
];

export const DAYS: number[]   = Array(31).fill(1).map((x, i) => i + 1); // [1 to 31];
export const MONTHS: string[] = Months;
// export const YEARS: number[]  = Array(85-18).fill(1).map((x, i) => ((new Date()).getFullYear() - 18) - i); // [currentYear-18 to currentYear-85]
export const YEARS: number[]  = Array((new Date()).getFullYear() - 1899 - 18).fill(1).map((x, i) => ((new Date()).getFullYear() - 18) - i); // [year 1900 to currentYear - 18]
