import { AbstractControl, ValidatorFn, ValidationErrors } from '@angular/forms';

export const validDate: ValidatorFn =  (control: AbstractControl): ValidationErrors | null => {
  let day   = control.value.split('/')[0];
  let month = control.value.split('/')[1];
  let year  = control.value.split('/')[2];

  if((!day && !month && !year) || (day === 'null' && month == 0 && year === 'null')) {
    return null;
  }

  if(!day || month == 0 || !year || day === 'null' || month === 'null' || year === 'null'){
    return { dateInvalid: true };
  }
  
  // Check if leap year -----------------------
  let daysInMonth;
  switch (month) {
    case '2':
      daysInMonth = ((year % 4 == 0 && year % 100) || year % 400 == 0) ? 29 : 28; break;
    case '9': case '4': case '6': case '11':
      daysInMonth =  30; break;
    default :
      daysInMonth =  31;
  }
  // ------------------------------------------

  if ((day>0 && month>0) && day > daysInMonth) {
    return { dateInvalid: true };
  }

  if ((day>0 && month<1) || (month>0 && day<1)){
    return { dateInvalid: true };
  }

  return null;
}
