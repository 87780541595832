import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormGroup, Validators, FormControl } from '@angular/forms';
import { OtpService } from '../../../services/otp/otp.service';
import { EmptyResponse } from '../../../models/http-service-models/common.body.model';
import { Router } from '@angular/router';
import { MatSnackBar } from '@angular/material/snack-bar';
import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { AndroidInterface } from 'src/app/models/android.model';

declare var Android: AndroidInterface;

@Component({
  selector: 'app-otp-validate',
  templateUrl: './otp-validate.component.html',
  styleUrls: ['./otp-validate.component.css']
})
export class OtpValidateComponent implements AfterViewInit {
  mobilePhoneValidationForm: FormGroup = new FormGroup({});
  otp = '';
  submitting = false;
  resendingSMS = false;

  @ViewChild('ngOtpInput') ngOtpInputRef: any;

  constructor(private router: Router, private otpService: OtpService, private snackBar: MatSnackBar) { 
  }

  ngAfterViewInit(): void {
    this.initForm();
  }

  // Get reference using ViewChild and the specified hash
  onOtpChange(otp): void{
    this.otp = otp;
    if(this.otp.length === 6){
      this.onOtpSubmit();
    }
  }

  private initForm(): void {
    this.ngOtpInputRef.setValue('');
    this.mobilePhoneValidationForm = new FormGroup({
      otpForm: new FormControl('', [
        Validators.required
      ])
    });
  }

  resendOTP() {
    this.resendingSMS = true;
    this.otpService.sendOTP(sessionStorage['mobilePhone'])
    .subscribe((success: EmptyResponse) => {
      this.resendingSMS = false;
      this.snackBar.open('To SMS στάλθηκε με επιτυχία', null, {
        duration: 2000,
      });
    }, (error: HttpErrorResponse) =>{
      this.resendingSMS = false;
      switch(error.status){
        case 409:
          switch(error?.error?.messages[0]?.code){
            case 1099:
              try{ Android.onError(JSON.stringify(error)); } catch (error){ }
              this.router.navigate(['/otp-limit-exceeded']);
              break;
            default: this.snackBar.open('To sms δεν στάλθηκε', null, {
              duration: 2000,
            });
          }
          break;
        default:
          this.snackBar.open('To sms δεν στάλθηκε', null, {
            duration: 2000,
          });
      }


      try{ Android.onError(JSON.stringify(error)); } catch (error){ }
  });

  }

  onOtpSubmit(): void {
    this.submitting = true;
    this.hideKeyboard();
    this.otpService.validate(sessionStorage['mobilePhone'], this.otp)
      .subscribe((success: HttpResponse<EmptyResponse>) => {
        this.submitting = false;
        sessionStorage.setItem('x-m-jwt-token', success.headers.get('x-m-jwt-token'));
        sessionStorage.setItem('validated', 'true');
        sessionStorage.setItem('sentOTP', 'false');
        sessionStorage.setItem('oneTimePin', this.otp);

        try{ Android.onOtpValidated(); } catch (error){ }

        this.router.navigate(['/registration']);
      }, (error: HttpErrorResponse) =>{
        this.submitting = false;
        this.otp = '';
        this.ngOtpInputRef.setValue('');
        if(error.error.messages){
          error.error.messages.forEach(message => {
            switch(message.code){
              case 1012:
                this.showSnackBarMessage('Ο κωδικός OTP είναι λάθος, παρακαλώ δοκιμάστε ξανά'); break;
                default: this.showSnackBarMessage('Απροσδιόριστο σφάλμα'); break;
            }
          });
        }

        try{ Android.onError(JSON.stringify(error)); } catch (error){ }
      });
  }

  showSnackBarMessage(message, duration = 2000): void {
    this.snackBar.open(message, null, {
      duration: duration,
    });
  }

  hideKeyboard(): void{
    var field = document.createElement('input');
    field.setAttribute('type', 'text');
    field.setAttribute('style', 'opacity:0;');
    document.body.appendChild(field);

    setTimeout(function() {
        field.focus();
        setTimeout(function() {
            field.setAttribute('style', 'display:none;');
        }, 50);
    }, 50);
  }
}