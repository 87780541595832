import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-otp-limit-page',
  templateUrl: './otp-limit-page.component.html',
  styleUrls: ['./otp-limit-page.component.css']
})
export class OtpLimitPageComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
