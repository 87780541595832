import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { OtpSentSmsGuard } from './guards/otp-sent-sms/otp-sent-sms.guard';
import { OtpValidatedGuard } from './guards/otp-validated/otp-validated.guard';
import { StoreNotFoundGuard } from './guards/store-not-found/store-not-found.guard';
import { OtpLimitPageComponent } from './view/pages/otp-limit-page/otp-limit-page.component';
import { OtpPageComponent } from './view/pages/otp-page/otp-page.component';
import { RegistrationFormPageComponent } from './view/pages/registration-form-page/registration-form-page.component';
import { StoreNotFoundComponent } from './view/pages/store-not-found/store-not-found.component';

const routes: Routes = [
  {path: '', redirectTo: '/mobile-validation/send-otp', pathMatch: 'full'},
  { path: 'mobile-validation', component: OtpPageComponent,
    children: [
      {
        path: 'send-otp',
        component: OtpPageComponent
      },
      {
        path: 'validate',
        component: OtpPageComponent,
        canActivate: [OtpSentSmsGuard]
      }
    ]
  },
  { path: 'registration', component: RegistrationFormPageComponent,  canActivate: [OtpValidatedGuard] },
  { path: 'otp-limit-exceeded', component: OtpLimitPageComponent },
  {path: '', redirectTo: '/mobile-validation/send-otp', pathMatch: 'full'},
  { path: '**', redirectTo: '/mobile-validation/send-otp', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [
    OtpValidatedGuard,
    StoreNotFoundGuard
  ]
})
export class AppRoutingModule { }
