<div class="gradient-back" style="height: 100vh; width: 100%;">
    <div style="position: absolute; top: 20%; width:100%" fxLayout="column" fxLayoutAlign="center center" >
        <img src="./assets/images/warning.svg" width="70%" style="max-width:350px">
        <br>
        <h2>Σφάλμα</h2>
        <p *ngIf="!storeCode || storeCode === 'undefined'">Ο κωδικός καταστήματος δεν υπάρχει</p>
        <p *ngIf="!baCode || baCode === 'undefined'">Ο κωδικός συμβούλου ομορφιάς δεν υπάρχει</p>
    </div>
    
    <small style="position: absolute; bottom: 0; right: 5px;">
        <div>Icons made by <a style="color:orange" href="https://www.flaticon.com/authors/pixel-perfect" title="Pixel perfect">Pixel perfect</a> from <a style="color:orange" href="https://www.flaticon.com/" title="Flaticon">www.flaticon.com</a></div>
    </small>
</div>