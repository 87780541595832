<app-loading-screen *ngIf="loading">
    Παρακαλώ περιμένετε
</app-loading-screen>
<div *ngIf="!loading"  fxLayout="column" fxLayoutAlign="center center" style="padding: 25px;">
    <h2>{{newMember ? 'Νέα εγγραφή' : 'Επεξεργασία στοιχείων'}}</h2>
    <form class="container" fxLayout="column" fxLayoutAlign="space-evenly stretch" [formGroup]="memberRegistrationForm" (ngSubmit)="onSubmit()">
        <div fxLayout="column" fxLayoutAlign="space-evenly stretch" >
            <div *ngFor="let schema of schemas" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px" style="min-height: 55px;">
                <!-- <p class="schema-name">{{schema.loyaltySchemaName}}</p> -->
                <img src="./assets/images/{{getSchemaName(schema.loyaltySchemaCode).toLowerCase()}}.png" height="32px">
                <div class="schema-registered" fxLayout="row" fxLayoutAlign="space-evenly stretch" *ngIf="schema.memberRegistered === 'REGISTERED'">
                    <p style="margin-top: 3px; margin-right:5px">{{memberRegistrationForm.get('salutation').value === 'MALE' ? 'Eγγεγραμμένος' : 'Eγγεγραμμένη'}}</p>
                    <mat-icon  *ngIf="schema.memberRegistered === 'REGISTERED'" >check_circle_outline</mat-icon>
                </div>
                
                <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="20px" *ngIf="schema.memberRegistered !== 'REGISTERED'">
                    <div  fxLayout="row" fxLayoutAlign="space-evenly stretch"  *ngIf="schema.memberRegistered === 'IN_PROGRESS'">
                        <p  class="schema-register-in-progress" style="margin-top: 3px; margin-right:5px">
                            Σε εξέλιξη
                        </p>
                        <mat-icon  style="color: black; cursor: pointer" (click)="schema.memberRegistered = 'NOT_REGISTERED'">close</mat-icon>
                    </div>

                    <button class="schema-btn"
                            mat-raised-button type="button"
                            *ngIf="schema.memberRegistered === 'NOT_REGISTERED'"
                            color="accent"
                            (click)="schema.memberRegistered = 'IN_PROGRESS'">
                            Εγγραφή
                    </button>
                   
                </div>
            </div>
        </div>
        <hr fxFill="100%">
        <br>
        <h3>Στοιχεία του μέλους</h3>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0"  fxLayoutGap="40px">
            <mat-form-field appearance="standard" >
                <mat-label>Κινητό</mat-label>
                <input matInput disabled value="{{mobileNumber}}">
            </mat-form-field>
            <mat-form-field appearance="standard" required >
                <mat-label>Προσφώνηση *</mat-label>
                <mat-select color="primary" formControlName="salutation"  
                class="myPanelClass">
                    <mat-option *ngFor="let gender of Gender | keyvalue" value="{{gender.key}}" >{{gender.value}}</mat-option>
                </mat-select>
                <mat-error>Η Προσφώνηση είναι <strong>υποχρεωτική</strong></mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" fxLayoutGap="40px" >
            <mat-form-field appearance="standard">
                <mat-label>Όνομα *</mat-label>
                <input  #name
                        type="text"
                        matInput
                        formControlName="name"
                        [value]="memberRegistrationForm.get('name').value | nameFormat"
                        (input)="nameFormatValue(memberRegistrationForm.get('name').value)"
                        autocomplete="off">
                <mat-error *ngIf="nameFormControl.hasError('required')">
                    Το όνομα είναι <strong>υποχρεωτικό</strong>
                </mat-error>
                <mat-error *ngIf="nameFormControl.hasError('pattern')">
                Παρακαλώ εισάγετε ένα <strong>έγκυρο</strong> όνομα
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Επώνυμο *</mat-label>
                <input  #surname
                        type="text"
                        matInput
                        formControlName="surname" 
                        [value]="memberRegistrationForm.get('surname').value | nameFormat"
                        (input)="surnameFormatValue(memberRegistrationForm.get('surname').value)"
                        autocomplete="off">
                <mat-error *ngIf="surnameFormControl.hasError('required')">
                    Το επώνυμο είναι <strong>υποχρεωτικό</strong>
                </mat-error>
                <mat-error *ngIf="surnameFormControl.hasError('pattern')">
                Παρακαλώ εισάγετε ένα <strong>έγκυρο</strong> επώνυμο
                </mat-error>
            </mat-form-field>
        </div>

        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" fxLayoutGap="40px" >
            <mat-form-field appearance="standard">
                <mat-label >Email</mat-label>
                <input #email
                        matInput
                        autocomplete="off"
                        type="email"
                        formControlName="email"
                        (input)="email.value = email.value.toLowerCase()">
                <mat-error *ngIf="emailFormControl.hasError('required')">
                    To email είναι <strong>υποχρεωτικό</strong>
                </mat-error>
                <mat-error *ngIf="emailFormControl.hasError('pattern') && !emailFormControl.hasError('required')">
                    Παρακαλώ εισάγετε ένα <strong>έγκυρο</strong> email
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Νομός *</mat-label>
                <mat-select #region 
                            type="text"
                            matInput
                            formControlName="region"
                            (selectionChange)="onRegionChange()">
                        <mat-option *ngFor="let reg of regions" [value]="reg.text">{{reg.text}}</mat-option>
                </mat-select>
                <mat-error *ngIf="regionFormControl.hasError('required')">
                    Ο νομός είναι <strong>υποχρεωτικός</strong>
                </mat-error>
            </mat-form-field>
        </div>
        
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" fxLayoutGap="40px" >
            <mat-form-field appearance="standard">
                <mat-label>Πόλη *</mat-label>
                <input #town 
                class="myPanelClass"
                        matInput
                        autocomplete="off"
                        formControlName="town"
                        (input)="town.value = town.value.toUpperCase(); fetchTownList(); fetchAddressList();"
                        [matAutocomplete]="townList">
                <mat-autocomplete townList #townList="matAutocomplete">
                    <mat-option *ngFor="let tn of towns" [value]="tn">
                        {{tn}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="townFormControl.hasError('required')">
                    Η πόλη είναι <strong>υποχρεωτική</strong>
                </mat-error>
            </mat-form-field>

            <mat-form-field appearance="standard">
                <mat-label>Διεύθυνση</mat-label>
                <input #address 
                class="myPanelClass" 
                        matInput
                        autocomplete="off"
                        formControlName="address"
                        [matAutocomplete]="addressList"
                        (input)="address.value = address.value.toUpperCase(); fetchAddressList()">
                <mat-autocomplete addressList #addressList="matAutocomplete">
                    <mat-option *ngFor="let addr of addresses" [value]="addr">
                        {{addr}}
                    </mat-option>
                </mat-autocomplete>
                <mat-error *ngIf="addressFormControl.hasError('pattern')">
                    Παρακαλώ εισάγετε μια <strong>έγκυρη</strong> διέυθυνση
                </mat-error>
            </mat-form-field>
        </div>
        <div fxLayout="row" fxLayout.lt-sm="column" fxLayoutGap.lt-sm="0" fxLayoutGap="40px" >
            <mat-form-field appearance="standard" fxFlex="50">
                <mat-label>Ταχυδρομικός κώδικας</mat-label>
                <input
                    matInput
                    autocomplete="off"
                    formControlName="postCode"
                    (keypress)="inpNum($event)">
                <mat-error *ngIf="postCodeFormControl.hasError('pattern')">Παρακαλώ εισάγετε τον <strong>5-ψήφιο</strong> ταχυδρομικό κώδικα</mat-error>
            </mat-form-field>
            <div fxFlex="50"></div>
        </div>
        <br>
        <mat-label style="margin-top: 10px;">Ημερομηνία γέννησης</mat-label>
        <div fxLayout="row"  fxLayoutGap="30px" style="margin-top: -7px;">
            <mat-form-field appearance="standard" fxFlex="20">
                <mat-label>Ημέρα</mat-label>
                <mat-select #dayOfBirth formControlName="dayOfBirth" (selectionChange)="onBirthDateChange()">
                    <mat-option value="null"></mat-option>
                    <mat-option *ngFor="let day of days" [value]="day">{{day}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="standard" fxFlex="40">
                <mat-label>Μήνας</mat-label>
                <mat-select #monthOfBirth formControlName="monthOfBirth" (selectionChange)="onBirthDateChange()">
                    <mat-option value="null"></mat-option>
                    <mat-option *ngFor="let month of months" [value]="month">{{month}}</mat-option>
                </mat-select>
            </mat-form-field>
            
            <mat-form-field appearance="standard" fxFlex="30" style="width: 10%;">
                <mat-label>Έτος</mat-label>
                <mat-select #yearOfBirth formControlName="yearOfBirth" (selectionChange)="onBirthDateChange()">
                    <mat-option value="null"></mat-option>
                    <mat-option *ngFor="let year of years" [value]="year">{{year}}</mat-option>
                </mat-select>
            </mat-form-field>
        </div>
        <input [hidden]="true" #birthDate matInput formControlName="birthDate">
        <mat-error *ngIf="submitted && birthDateFormControl.hasError('dateInvalid')" style="font-size: 10px; margin-top: -15px">
            Παρακαλώ εισάγετε μια <strong>έγκυρη</strong> ημερομηνία γέννησης
        </mat-error>

        <br>
        <h3 *ngIf="registrationInProgress()">Όροι και προϋποθέσεις</h3>
        <br>
        <div *ngFor="let schema of schemas" fxLayout="row" fxLayoutAlign="space-between center" fxLayoutGap="30px">
            <div *ngIf="schema.memberRegistered === 'IN_PROGRESS' ">
                <!-- <strong>{{schema.loyaltySchemaName}}</strong><br> -->
                <img src="./assets/images/{{getSchemaName(schema.loyaltySchemaCode).toLowerCase()}}.png" height="32px">
                <br>
                <small>
                    <mat-checkbox color="primary" (change)="onAcceptTermsChange(schema, $event)">
                        Έχω διαβάσει και αποδέχομαι τους &nbsp;
                    </mat-checkbox>
                    <a (click)="onShowTerms(schema.loyaltySchemaCode)">όρους και προϋποθέσεις</a>
                    <br>
                    <mat-error *ngIf="submitted && !hasAcceptedTerms(schema)">
                        Δεν έχετε <strong>αποδεχτεί</strong> τους όρους και προϋποθέσεις
                    </mat-error>
                </small>
                <br>
                <small>
                    <mat-checkbox color="primary" checked="true" (change)="onAcceptConsentsChange(schema, $event)">
                        Θέλω να ενημερώνομαι για τα νέα και 
                    </mat-checkbox>
                    τις προσφορές του προγράμματος
                </small>
                <br>
                <br>
                <br>
            </div>
        </div>

        <br><br>
        <div fxLayout="row" fxLayoutGap="40px">
            <button mat-raised-button color="default" type="button" (click)="onCancel()" fxFlex>Ακύρωση</button>
            <button mat-raised-button color="primary" type="submit" fxFlex [disabled]="isSaveDisabled() || saving">
                Αποθήκευση
                <img *ngIf="saving" height="40px" src="./assets/images/Spinner-1s-200px.gif" style="margin-right: -15px;">
            </button>
        </div>
    </form>
</div>