export enum RegistrationState {
    NOT_REGISTERED = 'NOT_REGISTERED',
    REGISTERED = 'REGISTERED',
    IN_PROGRESS = 'IN_PROGRESS',
    UNDEFINED = 'UNDEFINED'
}

export interface Schema {
    loyaltySchemaName: string;
    loyaltySchemaCode: string;
    memberRegistered: RegistrationState;
    accepted: boolean;
    consent: boolean;
}