import { Component, OnInit } from '@angular/core';
import { formatDate } from '@angular/common';
import { Schema, RegistrationState } from 'src/app/models/schema.model';
import { MatDialog } from '@angular/material/dialog';
import { TermsAndConditionsComponent } from 'src/app/view/components/terms-and-conditions/terms-and-conditions.component';
import { AbstractControl, FormControl, FormGroup, FormGroupDirective, NgForm, Validators } from '@angular/forms';
import { ErrorStateMatcher } from '@angular/material/core';
import { Gender } from 'src/app/models/member.model';
import { validDate } from 'src/app/validators/date-validator/date-validator.directive';
import { DAYS, MONTHS, YEARS } from 'src/app/utilities/date.const';
import { RegistrationService } from 'src/app/services/registration/registration.service';
import { CreatePersonRequest, CreatePersonResponse, PersonResponse } from 'src/app/models/http-service-models/create-member.body.model';
import { Email, Telephone, Address, LoyaltyMembership, Consent } from 'src/app/models/http-service-models/common.body.model';
import { RegisterStatusModalComponent } from 'src/app/view/components/register-status-modal/register-status-modal.component';
import { Router } from '@angular/router';
import { HttpErrorResponse } from '@angular/common/http';
import { UpdatePersonRequest } from 'src/app/models/http-service-models/update-person.body.model';
import { forkJoin, Observable } from 'rxjs';
import { UtilityService } from '../../../services/utility/utility.service';
import { Attribute } from 'src/app/models/attribute.model';
import { AndroidInterface } from '../../../models/android.model';
import { NameFormatPipe } from 'src/app/pipes/name-format/name-format.pipe';


declare var Android: AndroidInterface;

/** Error when invalid control is dirty, touched, or submitted. */
export class MyErrorStateMatcher implements ErrorStateMatcher {
  isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
    const isSubmitted = form && form.submitted;
    return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
  }
}

@Component({
  selector: 'app-registration-form-page',
  templateUrl: './registration-form-page.component.html',
  styleUrls: ['./registration-form-page.component.css']
})
export class RegistrationFormPageComponent implements OnInit{
  days = DAYS;
  months = MONTHS;
  years = YEARS;
  regions = [];
  towns = [];
  addresses = [];
  Gender = Gender;
  memberRegistrationForm: FormGroup = new FormGroup({});
  mobileNumber: string;
  matcher = new MyErrorStateMatcher();
  newMember = true;
  schemas?: Schema[];
  acceptedTerms: {schemaName: string, schemaCode: string, accepted: boolean}[] = [];
  submitted = false;
  loading = true;
  saving = false;
  personQCCode: string;
  emailQCCode: string;
  addressQCCode: string;

  constructor(public dialog: MatDialog,
              private router: Router,
              private registrationService: RegistrationService,
              private utilityServide: UtilityService) {}

  ngOnInit(): void {
    this.mobileNumber = sessionStorage.getItem('mobilePhone');

    this.utilityServide.getRegions()
      .subscribe((success) => {
        this.regions = success.lookupEntityList;

        this.initForm();
        this.fillForm(); // if person exists
      });
  }

  // Form control getters -------------------------------------------------------------------------------------------
  get salutationFormControl(): AbstractControl | null   { return this.memberRegistrationForm.get('salutation');   }
  get nameFormControl(): AbstractControl | null         { return this.memberRegistrationForm.get('name');         }
  get surnameFormControl(): AbstractControl | null      { return this.memberRegistrationForm.get('surname');      }
  get emailFormControl(): AbstractControl | null        { return this.memberRegistrationForm.get('email');        }
  get regionFormControl(): AbstractControl | null       { return this.memberRegistrationForm.get('region');       }
  get townFormControl(): AbstractControl | null         { return this.memberRegistrationForm.get('town');         }
  get addressFormControl(): AbstractControl | null      { return this.memberRegistrationForm.get('address');      }
  get postCodeFormControl(): AbstractControl | null     { return this.memberRegistrationForm.get('postCode');     }
  get dayOfBirthFormControl(): AbstractControl | null   { return this.memberRegistrationForm.get('dayOfBirth');   }
  get monthOfBirthFormControl(): AbstractControl | null { return this.memberRegistrationForm.get('monthOfBirth'); }
  get yearOfBirthFormControl(): AbstractControl | null  { return this.memberRegistrationForm.get('yearOfBirth');  }
  get birthDateFormControl(): AbstractControl | null    { return this.memberRegistrationForm.get('birthDate');    }
  // ----------------------------------------------------------------------------------------------------------------

  // Event Handlers -------------------------------------------------------------------------------------------------
  onSubmit(): void {
    this.submitted = true;

    if (this.memberRegistrationForm.valid && this.hasAcceptedAllTerms()){
      this.saving = true;
      if (this.newMember){ // member's first registration
        const person: CreatePersonRequest = this.getCreatePersonRequest();
        this.registrationService.createPerson(person)
          .subscribe((success: CreatePersonResponse) => {
            this.saving = false;
            // send feedback to android app ------------------------
            this.notifyAndroidApp(true, success.payload.data.QCCode);
            // -----------------------------------------------------
            this.dialog.open(RegisterStatusModalComponent, {data: {success: true}}).afterClosed().subscribe(() => {
              this.router.navigate(['/mobile-validation/send-otp']);
            });
          },
          (error: HttpErrorResponse) => {
            this.saving = false;
            this.dialog.open(RegisterStatusModalComponent, {data: {success: false}});

            try{ Android.onError(JSON.stringify(error)); } catch (error){ }
            // send feedback to android app ------------------------
            this.notifyAndroidApp(false, '');
            // -----------------------------------------------------
          });
      }
      else { // member already registered in a schema before
        const actions: Observable<any>[] = [];
        const person: UpdatePersonRequest = this.getUpdatePersonRequest();
        actions.push(this.registrationService.updatePerson(this.personQCCode, person));

        const getUpdateLoyaltyRequestBodys = this.getLoyaltyMembershipData();
        getUpdateLoyaltyRequestBodys.forEach(req => {
          actions.push(this.registrationService.addLoyaltyMembership(this.personQCCode, req));
        });

        forkJoin(actions)
          .subscribe(
            successes => {
              this.saving = false;
              // send feedback to android app ------------------------
              this.notifyAndroidApp(true, this.personQCCode);
              // -----------------------------------------------------
              this.dialog.open(RegisterStatusModalComponent, {data: {success: true}}).afterClosed().subscribe(() => {
                this.router.navigate(['/mobile-validation/send-otp']);
              });
            },
            (error: HttpErrorResponse) => {
                this.saving = false;
                try{ Android.onError(JSON.stringify(error)); } catch (error){ }
                // send feedback to android app ------------------------
                this.notifyAndroidApp(false, this.personQCCode);
                // -----------------------------------------------------
                this.dialog.open(RegisterStatusModalComponent, {data: {success: false}});
            });
      }
    }
  }

  onCancel(): void {
    this.router.navigate(['/mobile-validation/send-otp']);
  }

  onShowTerms(schemaCode): void {
    this.dialog.open(TermsAndConditionsComponent, {
      data: {schemaCode: schemaCode }
    });
  }

  onAcceptTermsChange(schema: Schema, $event: any): void {
    this.schemas.find(sc => sc.loyaltySchemaName === schema.loyaltySchemaName).accepted = $event.checked;
  }

  onAcceptConsentsChange(schema: Schema, $event: any): void {
    this.schemas.find(sc => sc.loyaltySchemaName === schema.loyaltySchemaName).consent = $event.checked;
  }

  onBirthDateChange(): void {
    const day   = this.dayOfBirthFormControl.value;
    const month = this.months.indexOf(this.monthOfBirthFormControl.value) + 1;
    const year  = this.yearOfBirthFormControl.value;
    this.birthDateFormControl.setValue(day  + '/' + month + '/' + year);
  }
  
  onRegionChange(): void {
    this.townFormControl.setValue('');
    this.addressFormControl.setValue('');

    this.fetchTownList();
  }
  // ----------------------------------------------------------------------------------------------------------------
  
  hasAcceptedAllTerms(): boolean {
    return this.schemas.find(sc => sc.memberRegistered === RegistrationState.IN_PROGRESS && !sc.accepted ) === undefined;
  }

  hasAcceptedTerms(schema: Schema): boolean {
    return this.schemas?.find(sc => sc.loyaltySchemaName === schema.loyaltySchemaName)?.accepted;
  }

  registrationInProgress(): boolean {
    return this.schemas.find(sc => sc.memberRegistered === 'IN_PROGRESS') != null;
  }

  isSaveDisabled(): boolean {
    return this.schemas.find(s => s.memberRegistered === 'IN_PROGRESS') == null && this.newMember;
  }

  // ----------------------------------------------------------------------------------------------------------------

  fetchRegionList(): void {
    this.utilityServide.getRegions()
      .subscribe((success) => {
        this.regions = success.lookupEntityList;
      });
  }

  fetchTownList(): void {
    this.utilityServide.getTowns(this.getRegionCode(this.regionFormControl.value), this.townFormControl.value)
      .subscribe(success => {
        this.towns = success.stringList;
      });
  }

  fetchAddressList(): void {
    this.utilityServide.getAddresses(this.getRegionCode(this.regionFormControl.value), this.townFormControl.value, this.addressFormControl.value)
      .subscribe(success => {
        this.addresses = success.stringList;
      });
  }

  private initForm(): void {
    this.memberRegistrationForm = new FormGroup({
      salutation: new FormControl('', [
        Validators.required,
      ]),
      name: new FormControl('', [
        Validators.pattern(/^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Z\s.-]+$/), // Accept latin & Greek letters, ' ', '.', '-'
        Validators.required,
      ]),
      surname: new FormControl('', [
        Validators.pattern(/^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Z\s.-]+$/), // Accept latin & Greek letters, ' ', '.', '-'
        Validators.required,
      ]),
      email: new FormControl('', [
        Validators.pattern(/^([\w+-]+\.)*[\w+-]+@([\w+-]+\.)*[\w+-]+\.[a-zA-Z]{2,4}$/),
      ]),
      region: new FormControl('', [
        Validators.required
      ]),
      town: new FormControl('', [
        Validators.required
      ]),
      address: new FormControl('', [
        // Accept latin & Greek letters, nubers, ' ', '&', '(', ')', ''', '-'
        Validators.pattern(/^[α-ωίϊΐόάέύϋΰήώΑ-ΩΆΈΊΌΎΏΉa-zA-Z0-9\s()&/'.-]+$/),
      ]),
      postCode: new FormControl('', [
        Validators.pattern(/^[0-9][0-9][0-9][0-9][0-9]$/), // Accept only 5 digits,
      ]),
      dayOfBirth: new FormControl('', [
      ]),
      monthOfBirth: new FormControl('', [
      ]),
      yearOfBirth: new FormControl('', [
      ]),
      birthDate: new FormControl('', [
        validDate
      ])
    });

    this.salutationFormControl.setValue('FEMALE');
  }


  private fillForm(): void{
    this.schemas = this.getSchemas();
    this.registrationService.getPersonDetails(this.mobileNumber)
      .subscribe((success: PersonResponse) => {        
        this.loading = false;
        if (success.payload.data.length < 1){
          return;
        }

        this.newMember = false;

        const personDetails = success.payload.data[0];
        const email = personDetails.emailList.find(e => e.isPrimary === true);
        const address = personDetails.addressList.find(e => e.addressType === 'PRIMARY_PERSON_ADDRESS');
        this.personQCCode = personDetails?.QCCode;
        this.emailQCCode = email?.QCCode;
        this.addressQCCode = address?.QCCode;

        this.salutationFormControl.setValue(personDetails.salutationCode);
        this.nameFormControl.setValue(personDetails.firstName);
        this.surnameFormControl.setValue(personDetails.lastName);
        this.emailFormControl.setValue(email?.emailAddress);
        this.addressFormControl.setValue(address?.addressLine2);

        this.regionFormControl.setValue(this.getRegionText(address?.regionCode));
        this.townFormControl.setValue(address?.town);
        this.postCodeFormControl.setValue(address?.postCode);
        if(address?.regionCode){
          this.fetchTownList();
        }
        if(address?.town){
          this.fetchAddressList();
        }

        let bdate = new Date(personDetails?.dateOfBirth?.substring(0,10));
        this.dayOfBirthFormControl.setValue(bdate?.getDate());
        this.monthOfBirthFormControl.setValue(this.months[bdate?.getMonth()]);
        this.yearOfBirthFormControl.setValue(bdate?.getFullYear());
        this.birthDateFormControl.setValue(bdate?.getDate() + '/' + bdate?.getMonth() + '/' + bdate?.getFullYear());

        // Set schema buttons state
        personDetails.loyaltyMembershipData.forEach(l => {
          let schemaIndex = this.schemas.findIndex(s => s.loyaltySchemaCode === l.schemaCode);
          if(schemaIndex > -1){
            this.schemas[schemaIndex].memberRegistered = RegistrationState.REGISTERED;
          }
        });
      },
      (error: HttpErrorResponse) => {
        this.loading = false;
        try{ Android.onError(JSON.stringify(error)); } catch (error){ }
      });
  }

  private getCreatePersonRequest(): CreatePersonRequest {
    return {
      firstName: this.nameFormControl.value,
      lastName: this.surnameFormControl.value,
      gender: this.salutationFormControl.value,
      addressList: this.getAddresses(),
      emailList: this.getEmails(),
      telephoneList: this.getTelephones(),
      consentList: [...this.getGeneralConsents(), ...this.getConsents()],
      attributes: this.getAttributes(),
      loyaltyMembershipData: this.getLoyaltyMembershipData(),
      dateOfBirth: this.getBirthDate(),
      languageCode: 'el',
      salutationCode: this.salutationFormControl.value,
      registrationStoreCode: sessionStorage.getItem('storeCode'),
      oneTimePin: sessionStorage.getItem('oneTimePin')
    };
  }

  private getUpdatePersonRequest(): UpdatePersonRequest {
    const addressModifications = [];
    let addressAdditions: Address[] = [];
    if (this.addressQCCode){
      addressModifications.push({
        addressLine2: {
          value: this.addressFormControl.value.toUpperCase()
        },
        town: {
          value: this.townFormControl.value.toUpperCase()
        },
        regionCode: {
          value: this.getRegionCode(this.regionFormControl.value)
        },
        postCode: {
          value: this.postCodeFormControl.value
        },
        QCCode: this.addressQCCode,
        action: 'UPDATE'
      });
    }
    else {
      addressAdditions = this.getAddresses();
    }

    const emailModifications = [];
    let emailAdditions: Email[] = [];
    if (this.emailQCCode){
      emailModifications.push({
        emailAddress: {
          value: this.emailFormControl.value
        },
        isPrimary: {
            value: true
        },
        QCCode: this.emailQCCode,
        action: 'UPDATE'
      });
    }
    else{
      emailAdditions = this.getEmails();
    }
        
    return {
      firstName: {
        value: this.nameFormControl.value
      },
      lastName: {
        value: this.surnameFormControl.value
      },
      dateOfBirth: {
        value: this.getBirthDate()
      },
      gender: {
        value: this.salutationFormControl.value
      },
      salutationCode: {
        value: this.salutationFormControl.value
      },
      addresses: {
        modifications: addressModifications,
        additions: addressAdditions
      },
      emails: {
        modifications: emailModifications,
        additions: emailAdditions
      },
      attributes: this.getAttributes(),
      consents: {
        additions: this.getConsents()
      }
    };
  }

  private getBirthDate(): string {
    if((!this.dayOfBirthFormControl.value   && 
        !this.monthOfBirthFormControl.value &&
        !this.yearOfBirthFormControl.value) || 
       (this.dayOfBirthFormControl.value   === 'null' &&
        this.monthOfBirthFormControl.value === 'null' &&
        this.yearOfBirthFormControl.value  === 'null')){
      return null;
    }

    const birthDate = new Date(this.yearOfBirthFormControl.value,
                               this.months.indexOf(this.monthOfBirthFormControl.value),
                               this.dayOfBirthFormControl.value)
                              .toISOString();

    return formatDate(birthDate, 'yyyy-MM-ddTHH:mm:ss.SSSZ', 'en-US');;
  }

  private getRegionCode(regionText): string {
    return this.regions?.find(r => r.text === regionText)?.code;
  }

  private getRegionText(regionCode): string {
    return this.regions?.find(r => r.code === regionCode)?.text;
  }

  private getTelephones(): Telephone[]{
    return [
      {
        telephoneNumber: this.mobileNumber,
        countryCode: 'gr',
        telephoneType: 'MOBILE',
        isPrimary: true
      }
    ];
  }

  private getAddresses(): Address[] {
    const addresses: Address[] = [];
    if (this.addressFormControl.value || this.townFormControl.value || this.regionFormControl.value || this.postCodeFormControl.value){
      addresses.push({
        addressLine2: this.addressFormControl.value.toUpperCase(),
        countryCode: 'gr',
        postCode: this.postCodeFormControl.value,
        regionCode: this.getRegionCode(this.regionFormControl.value),
        town: this.townFormControl.value.toUpperCase(),
        addressType: 'PRIMARY_PERSON_ADDRESS'
      });
    }
    return addresses;
  }

  private getEmails(): Email[]{
    const emails: Email[] = [];
    if (this.emailFormControl.value){
      emails.push({
          emailAddress: this.emailFormControl.value,
          isPrimary: true
      });
    }
    return emails;
  }

  private getLoyaltyMembershipData(): LoyaltyMembership[] {
    const loyaltyMembershipData: LoyaltyMembership[] = [];
    this.schemas.forEach(schema => {
      if (schema.memberRegistered === RegistrationState.IN_PROGRESS){
        let schemaName = '';
        switch (schema.loyaltySchemaCode){
          case '0000': schemaName = 'SEVENTEEN'; break;
          case '0001': schemaName = 'RADIANT'; break;
          case '0002': schemaName = 'LORVENN'; break;
          default: schemaName = '';
        }
        loyaltyMembershipData.push(
          {
            schemaCode: schema.loyaltySchemaCode,
            registrationCountryCode: 'gr',
            category: 'NormalMember',
            registrationSource: 'ONLINE',
            registrationStoreCode: sessionStorage.getItem('storeCode'),
            loyaltyCardData: {
              type: 'Permanent'
            },
            attributes: [
              {
                attributeName: schemaName + '_TERMS',
                attributeValue: true,
                dataType: 'BOOLEAN'
              }
            ]
          }
        );
      }
    });

    return loyaltyMembershipData;
  }

  private getAttributes() : Attribute[] {
    return [
      {
        attributeName: 'bacode',
        attributeValue: sessionStorage.getItem('baCode'),
        dataType: 'STRING'
      }
    ];
  }

  private getConsents() : Consent[] {
     // consent list --------------------------------------
     const consents: Consent[] = [];
     this.schemas.forEach(schema => {
       if (schema.memberRegistered === RegistrationState.IN_PROGRESS){
         let schemaName = '';
         switch (schema.loyaltySchemaCode){
           case '0000': schemaName = 'SEVENTEEN'; break;
           case '0001': schemaName = 'RADIANT'; break;
           case '0002': schemaName = 'LORVENN'; break;
           default: schemaName = '';
         }
         consents.push(
           {
             name: 'SMS_' + schemaName,
             flag: schema.consent,
             metadata: [
               {
                 key: 'Source',
                 value: 'MOBILE_APP'
               }
             ]
           }
         );
         consents.push(
           {
             name: 'VIBER_' + schemaName,
             flag: schema.consent,
             metadata: [
               {
                 key: 'Source',
                 value: 'MOBILE_APP'
               }
             ]
           }
         );
         consents.push(
           {
             name: 'EMAIL_' + schemaName,
             flag: schema.consent,
             metadata: [
               {
                 key: 'Source',
                 value: 'MOBILE_APP'
               }
             ]
           }
         );
       }
     });

     return consents;
  }

  private getGeneralConsents(): Consent[] {
    return [
      {
        name: 'SMS',
        flag: false,
        metadata: [
          {
            key: 'Source',
            value: 'MOBILE_APP'
          }
        ]
      },
      {
        name: 'VIBER',
        flag: false,
        metadata: [
          {
            key: 'Source',
            value: 'MOBILE_APP'
          }
        ]
      },
      {
        name: 'EMAIL',
        flag: false,
        metadata: [
          {
            key: 'Source',
            value: 'MOBILE_APP'
          }
        ]
      }
    ]
  }

  private getSchemas(): Schema[] {
    return [
      // {
      //   loyaltySchemaName: 'SEVENTEEN THE ART OF BEAUTY CLUB',
      //   loyaltySchemaCode: '0000',
      //   memberRegistered: RegistrationState.NOT_REGISTERED,
      //   accepted: false,
      //   consent: true
      // },
      {
        loyaltySchemaName: 'RADIANT INSIDER',
        loyaltySchemaCode: '0001',
        memberRegistered: RegistrationState.NOT_REGISTERED,
        accepted: false,
        consent: true
      },
      // {
      //   loyaltySchemaName: 'LORVENN LOYALS',
      //   loyaltySchemaCode: '0002',
      //   memberRegistered: RegistrationState.NOT_REGISTERED,
      //   accepted: false,
      //   consent: true
      // }
    ];
  }

  private getSchemaName(schemaCode): string {
    let schemaName = '';
    switch (schemaCode){
      case '0000': schemaName = 'SEVENTEEN'; break;
      case '0001': schemaName = 'RADIANT'; break;
      case '0002': schemaName = 'LORVENN'; break;
      default: schemaName = '';
    }

    return schemaName;
  }

  private notifyAndroidApp(success: boolean, personQCCcode: string): void {
    const rSchemas = [] ;
    this.schemas.filter(s => s.memberRegistered === RegistrationState.IN_PROGRESS).forEach(s => rSchemas.push({schemaCode: s.loyaltySchemaCode}));

    const regResult = {
      success: success,
      personQCCode: personQCCcode,
      schemas: rSchemas
    }

    try{ Android.onCustomerRegister(JSON.stringify(regResult)); } catch (error){ }
  }

  nameFormatPipe = new NameFormatPipe();
  nameFormatValue(value:any){
    value = this.nameFormatPipe.transform(value);
    this.nameFormControl.setValue(value);
  }

  surnameFormatValue(value:any){
    value = this.nameFormatPipe.transform(value);
    this.surnameFormControl.setValue(value);
  }
  
  // Ensure numeric values on number-inputs (for EDGE and FireFox)
  inpNum(e) {
    e = e || window.event;
    let charCode = (typeof e.which == "undefined") ? e.keyCode : e.which;
    let charStr = String.fromCharCode(charCode);
    if (!charStr.match(/^[0-9]+$/))
      e.preventDefault();
  }
}
