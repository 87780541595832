import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoyaltyMembership } from 'src/app/models/http-service-models/common.body.model';
import { UpdatePersonRequest } from 'src/app/models/http-service-models/update-person.body.model';
import { environment } from 'src/environments/environment';
import { CreatePersonRequest, CreatePersonResponse, PersonResponse } from '../../models/http-service-models/create-member.body.model';

@Injectable({
  providedIn: 'root'
})
export class RegistrationService {

  constructor(private http: HttpClient) { }

  getPersonDetails(mobileNumber: string): Observable<PersonResponse>{
    const endpoint = `${environment.coreApiURL}/protected/persons/search`;

    const body = {
      criteriaList: [
        {
          criteriaType: 'TELEPHONE',
          telephoneNumber: mobileNumber,
          telephoneType: 'MOBILE',
          countryCode: 'gr',
          isPrimary: true
        }
      ],
      pagination: {
        page: 1,
        pageSize: 100
      },
      sorting: {
        sortingField: 'ID',
        sortingOrder: 'DESC'
      }
    };

    return this.http.post<PersonResponse>(endpoint, body);
  }

  createPerson(person: CreatePersonRequest): Observable<CreatePersonResponse>{
    const endpoint = `${environment.coreApiURL}/protected/persons`;
    return this.http.post<CreatePersonResponse>(endpoint, person);
  }

  updatePerson(qcCode: string, person: UpdatePersonRequest): Observable<PersonResponse>{
    const endpoint = `${environment.coreApiURL}/protected/persons/${qcCode}/multidata-action`;
    return this.http.put<PersonResponse>(endpoint, person);
  }

  addLoyaltyMembership(personQCCode, loyaltyMembershipData: LoyaltyMembership) {
    const endpoint = `${environment.coreApiURL}/protected/persons/${personQCCode}/loyalty-membership`;
    return this.http.put<PersonResponse>(endpoint, loyaltyMembershipData);
  }
}
