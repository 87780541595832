<form [formGroup]="mobilePhoneValidationForm" (ngSubmit)="onOtpSubmit()" fxLayout="collumn" fxLayoutAlign="center center" autocomplete="off">
    <div class="card" fxLayout="column" fxLayoutAlign="space-evenly center">
        <p style="text-align: center;">
            Παρακαλώ εισάγετε τον <strong>6ψήφιο κωδικό</strong><br>που λάβατε μέσω SMS
        </p>
        <br>
        <ng-otp-input #ngOtpInput (onInputChange)="onOtpChange($event)" [config]="{length:6, allowNumbersOnly:true}"></ng-otp-input>
        <br>
        <small>
            Δεν λάβατε το SMS με τον 6-ψήφιο κωδικό;
            <br>
            <a (click)="resendOTP()">
                <strong>Επανάληψη αποστολής
                    <img *ngIf="resendingSMS"  height="25px" src="./assets/images/Spinner-1s-200px.gif" style="margin: -8px; margin-left: 0">
                </strong>
            </a>
        </small>
        <br>
    </div>    
</form>