
import { Component } from '@angular/core';
import { Store } from './models/store.model';
import { ActivatedRoute } from '@angular/router';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'hellenica-regate-crm-registration';
  store: Store = {
    name: '',
    code: ''
  };

  constructor() { }
}
