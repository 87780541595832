import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class OtpSentSmsGuard implements CanActivate {
  constructor(private router: Router) { }

  canActivate(): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const sentSMS = sessionStorage.getItem('sentOTP') && sessionStorage.getItem('sentOTP') === 'true';
    if (!sentSMS){
      this.router.navigate(['/mobile-validation']);
    }
    return sentSMS;
  }
}
