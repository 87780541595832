<form [formGroup]="mobilePhoneSendOTPForm" (ngSubmit)="onMobileSubmit()"
fxLayout="collumn" fxLayoutAlign="center center">
    <div class="card" fxLayout="column" fxLayoutAlign="center center">
        <p style="text-align: center;">
            Παρακαλώ εισάγετε έναν αριθμό<br><strong>κινητού τηλεφώνου </strong>
        </p>
        <div fxLayout="row" fxLayoutAlign="center start">
            <mat-form-field appearance="standard" required fxFlex="100">
                <span matPrefix>+30 &nbsp;</span>
                <mat-label>Κινητό</mat-label>
                <input matInput required type="number" placeholder="6900000000" formControlName="mobilePhone" maxlength="10" onKeyPress="if(this.value.length==10) return false;">
                <mat-icon matSuffix>phone</mat-icon>
                <mat-error *ngIf="mobilePhoneFormControl.hasError('required')">
                    Το κινητό τηλέφωνο είναι <strong>υποχρεωτικό</strong>
                </mat-error>
                <mat-error *ngIf="mobilePhoneFormControl.hasError('pattern')">
                    Παρακαλώ εισάγετε ένα <strong>έγκυρο</strong> κινητό τηλέφωνο
                </mat-error>
            </mat-form-field>
        </div>
        <br>
        <div fxLayout="row" fxLayoutAlign="center start" fxLayoutGap="20px">
            <button mat-raised-button type="submit" color="primary"
                    [disabled]="mobilePhoneFormControl.hasError('required') ||
                                mobilePhoneFormControl.hasError('pattern') || submitting">
                    Αποστολή κωδικού
                    <img *ngIf="submitting" height="40px" src="./assets/images/Spinner-1s-200px.gif" style="margin-right: -15px;">
            </button>
        </div>
    </div>    
</form>