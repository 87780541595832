import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { OtpService } from '../../../services/otp/otp.service';
import { EmptyResponse } from '../../../models/http-service-models/common.body.model';
import { HttpErrorResponse } from '@angular/common/http';
import { AndroidInterface } from 'src/app/models/android.model';

declare var Android: AndroidInterface;

@Component({
  selector: 'app-otp-page',
  templateUrl: './otp-page.component.html',
  styleUrls: ['./otp-page.component.css']
})
export class OtpPageComponent implements OnInit {
  otp = '';
  router: Router;
  constructor(private r: Router, private route: ActivatedRoute, private otpService: OtpService) {
      this.router = r;

      this.route.queryParams.subscribe(params => {
        sessionStorage.setItem('validated', 'false');
        sessionStorage.setItem('sentOTP', 'false');

        if(!sessionStorage.getItem('storeCode') || !sessionStorage.getItem('baCode') || 
           sessionStorage.getItem('storeCode') === 'undefined' || sessionStorage.getItem('baCode') === 'undefined'){
          sessionStorage.setItem('storeCode', params.storeCode);
          sessionStorage.setItem('baCode', params.baCode);
        }

        if (sessionStorage.getItem('storeCode') === 'undefined' || sessionStorage.getItem('baCode') === 'undefined'){
          this.router.navigate(['/store-not-found']);
        }

        if(params.mobilePhone){
          this.otpService.sendOTP(params.mobilePhone)
            .subscribe((success: EmptyResponse) => {
              sessionStorage.setItem('mobilePhone', params.mobilePhone);
              sessionStorage.setItem('sentOTP', 'true');
              this.router.navigate(['/mobile-validation/validate']);
            }, (error: HttpErrorResponse) =>{
              this.router.navigate(['/store-not-found']);
              switch(error.status){
                case 409:
                  switch(error?.error?.messages[0]?.code){
                    case 1099:
                      try{ Android.onError(JSON.stringify(error)); } catch (error){ }
                      this.router.navigate(['/otp-limit-exceeded']);
                      break;
                    default: break;
                  }
                  break;
                default:
                  break;
              }
              try{ Android.onError(JSON.stringify(error)); } catch (error){ }
            });
        }
      });
  }

  ngOnInit(): void {}
}
