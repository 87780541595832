<div fxLayout="column" fxLayoutAlign="space-around stretch" class="container">
    <div  fxLayout="column" fxLayoutAlign="center center">
        <img src="./assets/images/shutterstock_1532267915.svg" class="shadow-img" >
        <br>
        <p style="text-align: center;">
            Επικυρώστε το κινητό σας τηλεφώνο μέσω <br><br><strong style="font-size: 18px">"One Time Pin"</strong>
        </p>
    </div>

    <mat-tab-group class="header-less-tabs" [selectedIndex]="router.url === '/mobile-validation/validate' ? 1 : 0">
        <mat-tab label="Insert mobile">
            <app-otp-send></app-otp-send>
        </mat-tab>
        <mat-tab label="Insert one time pin">
            <app-otp-validate></app-otp-validate>
        </mat-tab>
    </mat-tab-group>
</div>