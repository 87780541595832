import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-store-not-found',
  templateUrl: './store-not-found.component.html',
  styleUrls: ['./store-not-found.component.css']
})
export class StoreNotFoundComponent implements OnInit {

  storeCode:string;
  baCode:string;

  constructor() {
    this.storeCode = sessionStorage.getItem('storeCode');
    this.baCode = sessionStorage.getItem('baCode');
   }

  ngOnInit(): void {
  }

}
